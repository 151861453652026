export const FETCH_REWARD_DETAIL_BEGIN = "FETCH_REWARD_DETAIL_BEGIN";
export const FETCH_REWARD_DETAIL_SUCCESS = "FETCH_REWARD_DETAIL_SUCCESS";
export const FETCH_REWARD_DETAIL_FAILURE = "FETCH_REWARD_DETAIL_FAILURE";

export const FETCH_CLAIM_SINGLE_TOKEN_BEGIN = "FETCH_CLAIM_SINGLE_TOKEN_BEGIN";
export const FETCH_CLAIM_SINGLE_TOKEN_SUCCESS = "FETCH_CLAIM_SINGLE_TOKEN_SUCCESS";
export const FETCH_CLAIM_SINGLE_TOKEN_FAILURE = "FETCH_CLAIM_SINGLE_TOKEN_FAILURE";

export const FETCH_CLAIM_LP_TOKEN_BEGIN = "FETCH_CLAIM_LP_TOKEN_BEGIN";
export const FETCH_CLAIM_LP_TOKEN_SUCCESS = "FETCH_CLAIM_LP_TOKEN_SUCCESS";
export const FETCH_CLAIM_LP_TOKEN_FAILURE = "FETCH_CLAIM_LP_TOKEN_FAILURE";