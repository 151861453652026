import logo from "../../../assets/img/lootex_logo.png";
import { tokens } from "../../configure";

const addToken = async () => {
  const tokenAddress = tokens.rewardToken.address;
  const tokenSymbol = tokens.rewardToken.token;
  const tokenDecimals = tokens.rewardToken.decimals;
  const tokenImage = logo;

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          // image: require("/assets/img/GUILD.png").default, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};
export { addToken };
