export const HOME_CONNECT_WALLET_BEGIN = "HOME_CONNECT_WALLET_BEGIN";
export const HOME_CONNECT_WALLET_SUCCESS = "HOME_CONNECT_WALLET_SUCCESS";
export const HOME_CONNECT_WALLET_FAILURE = "HOME_CONNECT_WALLET_FAILURE";
export const HOME_ACCOUNTS_CHANGED = "HOME_ACCOUNTS_CHANGED";
export const HOME_NETWORK_CHANGED = "HOME_NETWORK_CHANGED";

export const HOME_DISCONNECT_WALLET_BEGIN = "HOME_DISCONNECT_WALLET_BEGIN";
export const HOME_DISCONNECT_WALLET_SUCCESS = "HOME_DISCONNECT_WALLET_SUCCESS";
export const HOME_DISCONNECT_WALLET_FAILURE = "HOME_DISCONNECT_WALLET_FAILURE";

export const FETCH_DASHBOARD_BEGIN = "FETCH_DASHBOARD_BEGIN";
export const FETCH_DASHBOARD_SUCCESS = "FETCH_DASHBOARD_SUCCESS";
export const FETCH_DASHBOARD_FAILURE = "FETCH_DASHBOARD_FAILURE";

export const DO_BID_BEGIN = "DO_BID_BEGIN";
export const DO_BID_SUCCESS = "DO_BID_SUCCESS";
export const DO_BID_FAILURE = "DO_BID_FAILURE";

export const FETCH_TOKEN_APPROVAL_BEGIN = "FETCH_TOKEN_APPROVAL_BEGIN";
export const FETCH_TOKEN_APPROVAL_SUCCESS = "FETCH_TOKEN_APPROVAL_SUCCESS";
export const FETCH_TOKEN_APPROVAL_FAILURE = "FETCH_TOKEN_APPROVAL_FAILURE";

export const POOL_FETCH_BEGIN = "POOL_FETCH_BEGIN";
export const POOL_FETCH_SUCCESS = "POOL_FETCH_SUCCESS";
export const POOL_FETCH_FAILURE = "POOL_FETCH_FAILURE";

export const REWARD_POOL_FETCH_BEGIN = "REWARD_POOL_FETCH_BEGIN";
export const REWARD_POOL_FETCH_SUCCESS = "REWARD_POOL_FETCH_SUCCESS";
export const REWARD_POOL_FETCH_FAILURE = "REWARD_POOL_FETCH_FAILURE";

export const FETCH_WITHDRAW_BEGIN = "FETCH_WITHDRAW_BEGIN";
export const FETCH_WITHDRAW_SUCCESS = "FETCH_WITHDRAW_SUCCESS";
export const FETCH_WITHDRAW_FAILURE = "FETCH_WITHDRAW_FAILURE";
