import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "./history";
import homeReducer from "features/home/redux/reducer";
import commonReducer from "features/common/redux/reducer";
import stakeReducer from "features/stake/redux/reducer";
import priceReducer from "features/price/redux/reducer";
import rewardReducer from "features/reward/redux/reducer";
import leaderboardReducer from "features/leaderboard/redux/reducer";

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  stake: stakeReducer,
  price: priceReducer,
  reward: rewardReducer,
  leaderboard: leaderboardReducer
};

export default combineReducers(reducerMap);
