import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  POOL_FETCH_BEGIN,
  POOL_FETCH_SUCCESS,
  POOL_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { erc20ABI,singleTokenStakingABI, contracts, pools } from "../../configure";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";

export function fetchPoolDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: POOL_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;

      const multicall = new MultiCall(web3, contracts.multicall.address);

      let calls = [];
      for (let i =0; i < pools.length; i++) {
        let poolAddress = pools[i].poolAddress;
        let tokenContract = new web3.eth.Contract(
          erc20ABI,
          pools[i].tokenAddress
        );

        let poolContract = new web3.eth.Contract(
          singleTokenStakingABI,
          pools[i].poolAddress
        );

        let call = { result: tokenContract.methods.balanceOf(poolAddress) }
        calls.push(call);

        let call2 = {result: poolContract.methods.totalSupply()}

        calls.push(call2);
      }

      multicall
        .all([calls])
        .then(async([results]) => {
          let output = [];
          for (let i =0; i < pools.length; i++) {
            const poolTotalStaked = results[i*2].result ? convertAmountFromRawNumber(results[i*2].result): 0;
            const poolTotalSupply = results[i*2+1].result ? convertAmountFromRawNumber(results[i*2+1].result): 0;
            output[i] = {
              totalStaked: poolTotalStaked,
              totalSupply: poolTotalSupply
            }
          }
          dispatch({
            type: POOL_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: POOL_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchPoolDetails() {
  const dispatch = useDispatch();

  const { poolDetails, fetchPoolDetailsPending, fetchPoolDetailsDone } =
    useSelector(
      (state) => ({
        poolDetails: state.home.poolDetails,
        fetchPoolDetailsPending: state.home.fetchPoolDetailsPending,
        fetchPoolDetailsDone: state.home.fetchPoolDetailsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchPoolDetails(data));
    },
    [dispatch]
  );

  return {
    poolDetails,
    fetchPoolDetails: boundAction,
    fetchPoolDetailsDone,
    fetchPoolDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case POOL_FETCH_BEGIN:
      return {
        ...state,
        fetchPoolDetailsPending: true,
      };

    case POOL_FETCH_SUCCESS:
      return {
        ...state,
        poolDetails: action.data,
        fetchPoolDetailsDone: true,
        fetchPoolDetailsPending: false,
      };

    case POOL_FETCH_FAILURE:
      return {
        ...state,
        fetchPoolDetailsPending: false,
      };

    default:
      return state;
  }
}
