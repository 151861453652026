export const tokens = {
  rewardToken: {
    token: "LOOT",
    address: "0x721A1B990699eE9D90b6327FaaD0A3E840aE8335",
    decimals: 18,
  },
  extraRewardToken: {
    token: "GUILD",
    address: "0x83e9f223e1edb3486f876ee888d76bfba26c475a",
    decimals: 18,
    priceid:"blockchainspace"
  },
};
