import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  field: {
    height: "90px",
    marginTop: 20,
    padding: 15,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
  },
  showDetail: {
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-around",
    width: "100%",
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
    fontWeight: 600,
    "& fieldset": {},
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.grey,
      color: theme.palette.text.primary,
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: 25, padding: 11, marginTop: -20, textAlign: 'right', marginRight: 50 }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function ExchangeOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const { classes, style, disabled, setMax, text, availabletext, token, placeholder } =
    props;
  const filteredProps = _.omit(props, ["setMax", "startAdornment"]);

  if (token) {
    return (
      <div className={commonStyle.field} style={style}>
        <Grid container spacing={1}>
            <Grid item xs={2}>
              <img
                className="icon"
                src={require("assets/img/" + token.token + ".png").default}
              />
            </Grid>
            <Grid item xs={2}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <div style={{fontSize: 20, color: 'gray'}}>{text}</div>
                  <div style={{fontSize: 25}}>{token.token}</div>
                </Grid>
                <Grid item style={{ marginTop: 22, fontSize: 25}} xs={2}>
                  <div>{">"}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
                <span style={{ color: 'gray', float: "right", fontWeight: 600 }}>
                  {availabletext}
                </span>
                <TextField
                  {...filteredProps}
                  placeholder={placeholder || "0"}
                  classes={Object.assign({}, commonClasses, classes)}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    startAdornment: props.startAdornment,
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={commonStyle.inputAdornment}
                      >
                        <Button
                          onClick={setMax}
                          color="primary"
                          size="sm"
                          disabled={disabled}
                        >
                          MAX
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
            </Grid>
        </Grid>

      </div>
    );
  } else {
    return (
      <div className={commonStyle.field}>
        <div>
          <span className="valueHeader">{text}</span>
          <span style={{ float: "right", fontWeight: 600 }}>
            {availabletext}
          </span>
        </div>
        <TextField
          {...filteredProps}
          placeholder={placeholder || "0"}
          classes={Object.assign({}, commonClasses, classes)}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: props.startAdornment,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment
                position="end"
                className={commonStyle.inputAdornment}
              >
                <Button
                  onClick={setMax}
                  color="primary"
                  size="sm"
                  disabled={disabled}
                >
                  MAX
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </div>
    );
  }
}

ExchangeOutlinedInput.defaultProps = {
  fullWidth: true,
};
