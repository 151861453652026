import axios from "axios";
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_PRICE_BEGIN,
  FETCH_PRICE_SUCCESS,
  FETCH_PRICE_FAILURE,
} from './constants';
import { uniswapV2PairABI, liquidityBootstrappingPoolABI, balancerVaultABI, contracts, tokens } from 'features/configure';
import { convertAmountFromRawNumber } from "../helpers/bignumber";
import _ from "lodash";

export function fetchPrice({ web3, address }) {
  return dispatch => {
    dispatch({
      type: FETCH_PRICE_BEGIN
    });

    let lowerAddress = address? address.toLowerCase():"";
    console.log(lowerAddress);
    const promise = new Promise((resolve, reject) => {

    // const copperLaunchPoolContract = new web3.eth.Contract(liquidityBootstrappingPoolABI, contracts.copperLaunchPoolContract.address);
    // const balancerVaulContract = new web3.eth.Contract(balancerVaultABI, contracts.balancerVault.address);
    const uniswapPairContract = new web3.eth.Contract(uniswapV2PairABI, contracts.uniswapLpPairAddress.address);

      Promise.all([
        // copperLaunchPoolContract.methods.getNormalizedWeights().call(),
        // balancerVaulContract.methods.getPoolTokens(contracts.balancerVault.poolId).call(),
        // axios.get('https://api2.sushipro.io/', {
        //   params: { action: 'get_pair', chainID: '1', pair:"0xc2Ce29Af8930fc32Ca24ccB76C2866fB2Bf692fE" },
        // }),

       

        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: 'loot', vs_currencies: 'usd' },
        }),
        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids: 'ethereum', vs_currencies: 'usd' },
        }),
        uniswapPairContract.methods.getReserves().call(),
        uniswapPairContract.methods.totalSupply().call(),
        axios.get(`https://analytics.vault.inc/api/stats/priceHistory?name=lootex`),
        axios.get(`https://analytics.vault.inc/api/reward/signature?address=${lowerAddress}&project=lootex`),
        axios.get(`https://analytics.vault.inc/api/reward/daily?address=${lowerAddress}&project=lootex`),
        axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: { ids:tokens.extraRewardToken.priceid , vs_currencies: 'usd' }
        })
      ]).then(data => {
        dispatch({
          type: FETCH_PRICE_SUCCESS,
          data
        });
        resolve(data);
      })
        .catch(error => {
          dispatch({
            type: FETCH_PRICE_FAILURE
          });
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchPrice() {
  const dispatch = useDispatch();

  const { fetchPricePending, priceData, lpData, ethData,chart, extraReward, extraPriceData } = useSelector(state => ({
    fetchPricePending: state.price.fetchPricePending,
    priceData: state.price.priceData,
    lpData: state.price.lpData,
    ethData: state.price.ethData,
    chart: state.price.chart,
    extraReward: state.price.extraReward,
    extraPriceData: state.price.extraPriceData
  }));

  const boundAction = useCallback(
    data => {
      return dispatch(fetchPrice(data));
    },
    [dispatch]
  );

  return {
    fetchPrice: boundAction,
    fetchPricePending,
    priceData,
    extraReward,
    extraPriceData,
    lpData,
    ethData,
    chart,
  };
}

export function reducer(state = { fetchPricePending: false, priceData: {}, lpData: {},chart:[] }, action) {
  switch (action.type) {
    case FETCH_PRICE_BEGIN:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_SUCCESS:
      // const tokenWeight = convertAmountFromRawNumber(action.data[0][0]);
      // const wethWeight = convertAmountFromRawNumber(action.data[0][1]);
      // const tokenBalances = convertAmountFromRawNumber(action.data[1].balances[0]);
      // const wethBalances = convertAmountFromRawNumber(action.data[1].balances[1], 18);
      let chart = action.data[4].data
      const extraReward =  action.data[5].data
      const ethData = action.data[1].data['ethereum'].usd
      const priceData = action.data[0].data['loot'].usd
      const extraPriceData = action.data[7].data[tokens.extraRewardToken.priceid].usd;
      let rewardAmount = action.data[6].data ? action.data[6].data.totalReward : 0;

      let latest = _.last(chart);
      
      let modifiedLatest = (_.dropRight(latest));
      modifiedLatest.push(priceData);
      
      
      let modifiedChart = (_.dropRight(chart));
      modifiedChart.push(modifiedLatest);
      
      // const priceData = action.data[0].data['blockchainspace'].usd;
      const lpData = {
        tokenReserve: convertAmountFromRawNumber(action.data[2]._reserve0),
        baseReserve: convertAmountFromRawNumber(action.data[2]._reserve1),
        totalSupply: convertAmountFromRawNumber(action.data[3])
      }
      return {
        ...state,
        chart: modifiedChart,
        priceData: priceData,
        ethData: ethData,
        extraPriceData,
        extraReward:{...extraReward,rewardAmount:rewardAmount},
        lpData: lpData,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    case FETCH_PRICE_FAILURE:
      return {
        ...state,
        fetchPricePending: {
          ...state.fetchPricePending
        },
      };

    default:
      return state;
  }
}
