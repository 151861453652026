export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  liquidityMiningManager: {
    address: "0x16AA336FCE07d1d7fC7896b12Fe012Ee42E846ea",
  },
  escrowedReward: {
    address: "0x3eC4f3D8698BF4DF880D53Dd39A34D14D10f19dc",
  },
  view: {
    address: "0x50994C0fccF0DBAE68675Bc2A82D1874f1B518a2",
  },
  singleTokenPool: {
    address: "0xe366f742A0d7A679446198487550A362076833A5",
  },
  lpPool: {
    address: "0xab08b85Be2066165Deae128D582d62E5377209B8",
  },
  uniswapLpPairAddress : {
    address: "0x30Feee843fcae357406CeFce0973A054286A4D75"
  },
  copperLaunchPoolContract:{
    address: "0xeEDcA0C2cBa983b718C66094fC8E41F9eD52F82a"
  },
  balancerVault: {
    address: "0xba12222222228d8ba445958a75a0704d566bf2c8",
    poolId: "0xeedca0c2cba983b718c66094fc8e41f9ed52f82a00020000000000000000011c"
  },
  dualRewardPool: {
    address: "0x666958a9d7d11Fb75541E78b17C0F21F927cFeF9",
  },
  extraEscrowedReward: {
    address: "0xb19e239db03ab1cb45894a5ed586bc2bbddea9c6",
  },
};
