import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { useConnectWallet } from "features/home/redux/hooks";
import { useFetchPrice } from "../price/redux/hooks";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomTable from "components/CustomTable/CustomTable.js";
import _ from "lodash";
import Stake from "features/stake/Stake.js";
import moment from "moment";
import CustomOutlinedInput from "components/CustomOutlinedInput/CustomOutlinedInput";
import snapshot from "../../assets/data/leaderboard.json";
import Slider from "@material-ui/core/Slider";
import banner from "assets/img/banner.png";
import discordIcon from "assets/img/discord.png";
import twitterIcon from "assets/img/twitter.png";
import gitbookIcon from "assets/img/gitBook.png";
import telegramIcon from "assets/img/telegram.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BigNumber from "bignumber.js";
import { useFetchLeaderboardDetail } from "./redux/hooks";
import { links, tokens,pools } from "features/configure";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 40,
    lineHeight: 1.1,
    color: "white",
  },
  dateText: {
    fontWeight: 700,
    fontSize: 34,
  },
  bidInfo: {
    backgroundColor: "rgba(255,255,255,0.2)",
    textAlign: "right",
    marginTop: 30,
    padding: 20,
    fontSize: 44,
    lineHeight: 1.1,
    fontWeight: 700,
  },
  bidSpan: {
    fontSize: 24,
    fontWeight: 500,
  },
  bidField: {
    backgroundColor: "#1E2025",
    marginTop: 50,
    padding: 20,
  },
  card: {
    flexGrow: 1,
    maxWidth: 400,
    verticalAlign: "middle",
    backgroundColor: "#1E2025",
    overflow: "hidden",
    borderRadius: 10,
    margin: "0 auto",
    marginTop: 10,
    marginRight: 10,
    padding: "10px 20px",
    fontSize: 18,
  },
  cardSubTitle: {
    fontSize: 14,
    marginTop: 5,
  },
  timeSpan: {
    fontSize: 28,
    marginLeft: "5px",
  },
  time: {
    fontSize: 32,
    marginLeft: "5px",
  },
  tooltip: {
    backgroundColor: "rgba(255,255,255,0.5)",
    margin: "0 10px",
    padding: 3,
    textAlign: "center",
    verticalAlign: "middle",
  },

  grayText: {
    color: "rgba(255,255,255,0.6)",
  },
  heading: {
    fontSize: 20,
    color: "rgba(255,255,255,0.6)",
    textAlign: "right",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "white",
  },
}))(LinearProgress);

const Leaderboard = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const { web3, address } = useConnectWallet();
  const [limit, setLimit] = useState(200);
  const { fetchPrice, priceData } = useFetchPrice();
  const { fetchLeaderboardDetail, fetchLeaderboardDetailPending, detail } =
  useFetchLeaderboardDetail();
  const name = "lootex"
  useEffect(() => {
    if (web3 && address) {
      fetchLeaderboardDetail({ name, limit });
      fetchPrice({web3});
      const id = setInterval(() => {
        fetchLeaderboardDetail({ name, limit });
        fetchPrice({web3});
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3, address, name, limit]);


  const leaderboard = detail.users ? detail.users : [];

  const listData = _.filter(leaderboard, function (o) {
    if (!search) return o;
    return _.startsWith(o.address, search);
  });

  const changeInputValue = (event) => {
    let value = event.target.value;
    setSearch(value);
  };
  const userRank = _.find(leaderboard, { address: address })
    ? parseInt(_.find(leaderboard, { address: address }).rank)
    : "";

  const progressValue = userRank
    ? userRank < 200
      ? userRank < 100
        ? userRank < 50
          ? 100
          : 75
        : 50
      : 25
    : 0;


  return (
    <>
      <a href="https://lootex.io/" target="_blank">
        <img
          src={banner}
          style={{ height: 400, width: "100%", objectFit: "contain" }}
        />
      </a>
      <div
        style={{
          position: "relative",
          margin: "0 auto",
          paddingTop: 40,
          maxWidth: 1100,
          minHeight: "100vh",
        }}
      >
        <Grid container spacing={2}>
        <Grid container style={{ textAlign: "center" }}>
          <Grid item xs={2} />
          <Grid item xs={2}>
            <a
              href="https://lootex.gitbook.io/docs/question-and-answer"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={gitbookIcon} className="icon" />
            </a>
          </Grid>
          <Grid item xs={2}>
            <a
              href="https://t.me/lootex"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={telegramIcon} className="icon" />
            </a>
          </Grid>
          <Grid item xs={2}>
            <a
              href="https://twitter.com/LootexIO"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={twitterIcon} className="icon" />
            </a>
          </Grid>
          <Grid item xs={2}>
            <a
              href="https://lihi1.com/g9Rc0"
              target="_blank"
              className={classes.itemLink}
            >
              <img src={discordIcon} className="icon" />
            </a>
          </Grid>
          <Grid item xs={2} />
        </Grid>
          <Grid item xs={12}>
            <h1 className={classes.title}>Staking Leaderboard</h1>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{color:'white'}}>Lootex Staking Competition </h3>
            The rewards will be revealed soon.
            <br />
            <br />
            <p style={{ color: "red" }}>
              
            </p>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="card" style={{ height: 220 }}>
              <h1>Top 100 - 200</h1>
              <h2>TBD</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="card" style={{ height: 220 }}>
              <h1>Top 51 - 100</h1>
              <h2>TBD</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="card" style={{ height: 220 }}>
              <h1>Top 50</h1>
              <h2>TBD</h2>
            </div>
          </Grid>

          <Grid xs={12}>
            <div className="card" style={{ textAlign: "left" }}>
              <h2>You : {address}</h2>
              <BorderLinearProgress
                variant="determinate"
                value={progressValue}
              />
              <Grid
                container
                spacing={3}
                style={{ margin: "5px", textAlign: "right" }}
              >
                <Grid item xs={2} />
                <Grid item xs={2}>
                  <span>Top 200</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Top 100</span>
                </Grid>
                <Grid item xs={4}>
                  <span>Top 50</span>
                </Grid>
              </Grid>
              <h3>Rank : {userRank}</h3>

              <Button
                color="secondary"
                style={{ width: "100%" }}
                onClick={() => {
                  window.open(
                    "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x721a1b990699ee9d90b6327faad0a3e840ae8335"
                  );
                }}
              >
                Buy LOOT
              </Button>
            </div>
          </Grid>

          <Grid xs={12} style={{ marginBottom: 20 }}>
            <div className="card">
              <CustomOutlinedInput
                value={search}
                placeholder="search address"
                onClick={() => {}}
                onChange={changeInputValue}
              />

              <CustomTable
                leftText={{}}
                headers={["Rank", `Address`, "Contributed"]}
                contents={listData.map((row, index) => {
                  const contributed = `$${new BigNumber(parseFloat(row.totalDeposit)).multipliedBy(priceData).toFormat(2)}`
                  return [
                    `#${row.rank}`,
                    row.address,
                    `${contributed}`
                  ];
                })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Leaderboard;
