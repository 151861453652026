import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_DASHBOARD_BEGIN,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from "./constants";
import { contracts, viewABI } from "../../configure";
import { convertAmountFromRawNumber } from "../../helpers/bignumber";

export function fetchDashboard({ address, web3 }) {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DASHBOARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;

      const viewContract = new web3.eth.Contract(
        viewABI,
        contracts.view.address
      );

      viewContract.methods
        .fetchData(address)
        .call()
        .then((results) => {
          dispatch({
            type: FETCH_DASHBOARD_SUCCESS,
            data: results,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: FETCH_DASHBOARD_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchDashboard() {
  const dispatch = useDispatch();

  const { detail, fetchDashboardPending } = useSelector(
    (state) => ({
      fetchDashboardPending: state.home.fetchDashboardPending,
      detail: state.home.detail,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchDashboard(data));
    },
    [dispatch]
  );

  return {
    detail,
    fetchDashboard: boundAction,
    fetchDashboardPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_BEGIN:
      return {
        ...state,
        fetchDashboardPending: true,
      };

    case FETCH_DASHBOARD_SUCCESS:
      const pools = action.data.pools;
      const poolLength = pools.length;
      let poolsInfo = [];

      for (let i = 0; i < poolLength; i++) {
        let poolInfo = {
          accountTotalDeposit: convertAmountFromRawNumber(
            pools[i].accountTotalDeposit
          ),
          accountPendingRewards: convertAmountFromRawNumber(
            pools[i].accountPendingRewards
          ),
          deposits: pools[i].deposits,
        };
        poolsInfo.push(poolInfo);
      }

      return {
        ...state,
        detail: {
          poolLength: poolLength,
          pools: poolsInfo,
        },
        fetchDashboardPending: false,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        fetchDashboardPending: false,
      };

    default:
      return state;
  }
}
